<template>
  <a-modal v-model="visible" :footer="null" :title="'登记列表'" width="70%" :closable="false">
    <s-table
      ref="table"
      :locale="{ emptyText: '尚未登记公文，请先登记' }"
      :columns="columns"
      :data="loadData"
      :showTools="false"
      :alert="false"
      :rowKey="(record) => record.id"
    >
      <span slot="urgencyDegree" slot-scope="text">
        {{ Filter(flow_urgency_degree, text) }}
      </span>
      <span slot="approvalStatus" slot-scope="text" :class="text != 2 ? 'fred' : ''">
        {{ Filter(flow_approval_status, text) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a class="bts" @click="addApproval(record)">发起审批</a>
      </span>
    </s-table>
  </a-modal>
</template>
<script>
import { documentRegisterBillPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'

export default {
  components: {
    STable,
  },
  data() {
    return {
      visible: false,
      flow_urgency_degree: [],
      flow_approval_status: [],
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
        },

        {
          title: '发文字号',
          dataIndex: 'documentNumber',
        },
        {
          title: '紧急程度',
          dataIndex: 'urgencyDegree',
          scopedSlots: { customRender: 'urgencyDegree' },
        },
        {
          title: '状态',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' },
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      loadData: (parameter) => {
        return documentRegisterBillPage(parameter).then((res) => res.data)
      },
    }
  },
  mounted() {
    this.getDict()
  },
  methods: {
    addApproval(record) {
      this.visible = false
      this.$emit('addApproval', record)
    },
    open() {
      this.visible = true
    },
    Filter(arr, s) {
      if (s !== 0 && !s) return ''
      return arr.find((item) => Number(item.code) === Number(s))?.value
    },
    getDict() {
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
        this.flow_approval_status = res.data
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .s-table-tool {
  margin-bottom: 0;
}
</style>
